<template>
  <div class="pt-2">
    <div class="text-center">
      <vuexy-logo class="mb-50" />
    </div>
    <div class="px-1" v-if="false">
      <!-- <span class="font-medium-1 mr-50">Extend Session:</span>
      <b-button
        v-for="item in timerData"
        :key="item.value"
        variant="outline-primary"
        class="mr-50 mb-50 text-dark bg-white btn-icon"
      >
        {{ item.text }}
      </b-button> -->
      <countdown
        ref="countdown"
        :time="900000"
        :auto-start="false"
        :transform="transformSlotProps"
      >
        <!-- @end="next()"
                @progress="timeProgress" -->
        <template slot-scope="props">
          <span
            v-if="started"
            class="font-medium-3 font-weight-bold mb-0 text-danger ml-50"
            >{{ props.minutes }} : {{ props.seconds }} left</span
          >
        </template>
      </countdown>
    </div>

    <iframe
      class="border-0 w-100 set-aspect"
      src="https://p2p.mirotalk.com/join/gmvcgvphgp"
      allow="microphone; camera"
    ></iframe>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BMediaAside,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BSpinner,
  BFormTextarea,
  BEmbed,
  BContainer,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import VuexyLogo from "@core/layouts/components/Logo.vue";

export default {
  components: {
    VuexyLogo,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BMediaAside,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    BSpinner,
    BFormTextarea,
    BEmbed,
    vSelect,
    flatPickr,
    BContainer,
  },

  directives: {
    "b-tooltip": VBTooltip,
  },
  created() {
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", "full");
    // setTimeout(() => {
    //   this.$refs["countdown"].start();
    //   this.started = true;
    // }, 12000);
  },
  beforeDestroy() {
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", "boxed");
  },
  data() {
    return {
      started: false,
      counter: 0,
      timerData: [
        { text: "15 min", value: 15000 },
        { text: "30 min", value: 30000 },
      ],
    };
  },
  methods: {
    transformSlotProps(props) {
      const formattedProps = {};
      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = value < 10 ? `0${value}` : String(value);
      });
      return formattedProps;
    },
    extend(val) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to extend the session?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(val);
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.set-aspect {
  /* aspect-ratio: 16/9; */
  height: 85vh;
}

/* @media (max-width: 768px) {
  .set-aspect {
    height: 80vh;
  }
} */
</style>
